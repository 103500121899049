// @ts-ignore
import React, { useState} from "react"
import style from "./index.module.less"
import {  OneBlock } from "./one-block"
import { useIntl } from "react-intl"



const Index = ({ inView }: { inView: boolean }) => {
  const intl=useIntl()
  const open = inView
  const [checkedYear, setCheckedYear] = useState(2024)
  const Time_Line_Global = {
    2024:[
      {
        title: intl.formatMessage({ id: "timeline.month.10" }),
        content: intl.formatMessage({ id: "about_timeline_2024_10_1" }),
      }, 
      {
        title: intl.formatMessage({ id: "timeline.month.7" }),
        content: intl.formatMessage({ id: "about_timeline_2024_7_1" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.6" }),
        content: intl.formatMessage({ id: "about_timeline_2024_6_1" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: intl.formatMessage({ id: "about_timeline_2024_5_1" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about_timeline_2024_1_1" }),
      }
    ],
    2023:[
      {
        title: intl.formatMessage({ id: "timeline.month.11" }),
        content: `${intl.formatMessage({ id: "about_timeline_2023_11_1" })} <br />${intl.formatMessage({ id: "about_timeline_2023_11_2" })}`,
      }, 
     
      {
        title: intl.formatMessage({ id: "timeline.month.10" }),
        content: intl.formatMessage({ id: "about_timeline_2023_10_1" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.6" }),
        content: `${intl.formatMessage({ id: "about_timeline_2023_6_1" })}<br/>${intl.formatMessage({ id: "about_timeline_2023_6_2" })}`,
      },
      {
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: intl.formatMessage({ id: "about_timeline_2023_5_1" }),
      }
    ],
    2022:[
      {
        title: intl.formatMessage({ id: "timeline.month.8" }),
        content: intl.formatMessage({ id: "about_timeline_2022_8_1" }),
      }, 
      {
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: intl.formatMessage({ id: "about_timeline_2022_5_1" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.2" }),
        content: intl.formatMessage({ id: "about.timeline.1" }),
      }
    ],
    2021:[
      {
        title: intl.formatMessage({ id: "timeline.month.11" }),
        content: intl.formatMessage({ id: "about.timeline.2" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.3" }),
      },{ title:"",content:''}
    ],
    2020:[
      {
        title:  intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.4" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.8" }),
        content: intl.formatMessage({ id: "about.timeline.5" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.6" }),
      }
    ],
    2019:[
      {
        title:intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.7" }),
      },
      {
        title:intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.8" }),
      },{ title:"",content:''}
    ],
    2018:[],
    2017:[
      {
        title:intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.9" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.10" }),
      },{ title:"",content:''}
    ]
  }

  const Time_Line_JP={
    2024:[
      {
        title: intl.formatMessage({ id: "timeline.month.10" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2024_10_1" }),
      },  {
        title: intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2024_9_1" }),
      },   {
        title: intl.formatMessage({ id: "timeline.month.7" }),
        content: `${intl.formatMessage({ id: "about_jp_timeline_2024_7_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2024_7_2" })}`,
      },   {
        title: intl.formatMessage({ id: "timeline.month.6" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2024_6_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: `${intl.formatMessage({ id: "about_jp_timeline_2024_5_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2024_5_2" })}`,
      },  {
        title: intl.formatMessage({ id: "timeline.month.3" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2024_3_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.2" }),
        content:`${ intl.formatMessage({ id: "about_jp_timeline_2024_2_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2024_2_2" })}`,
      }, {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2024_1_1" }),
      }, 
    ],
    2023:[
      {
        title: intl.formatMessage({ id: "timeline.month.12" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2023_12_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.11" }),
        content: `${intl.formatMessage({ id: "about_jp_timeline_2023_11_1" })}<br/>${ intl.formatMessage({ id: "about_jp_timeline_2023_11_2" })}`,
      }, {
        title: intl.formatMessage({ id: "timeline.month.10" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2023_10_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2023_9_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.7" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2023_7_1" }),
      }, {
        title: intl.formatMessage({ id: "timeline.month.6" }),
        content: `${ intl.formatMessage({ id: "about_jp_timeline_2023_6_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2023_6_2" })}`,
      },  {
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2023_5_1" }),
      },
    ],
    2022:[
      {
        title: intl.formatMessage({ id: "timeline.month.12" }),
        content:`${intl.formatMessage({ id: "about_jp_timeline_2022_12_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2022_12_2" })}` ,
      },{
        title: intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2022_9_1" }),
      },{
        title: intl.formatMessage({ id: "timeline.month.8" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2022_8_1" }),
      },{
        title: intl.formatMessage({ id: "timeline.month.6" }),
        content: `${intl.formatMessage({ id: "about_jp_timeline_2022_6_1" })}<br/>${intl.formatMessage({ id: "about_jp_timeline_2022_6_2" })}`,
      },{
        title: intl.formatMessage({ id: "timeline.month.5" }),
        content: intl.formatMessage({ id: "about_jp_timeline_2022_5" }),
      },{
        title: intl.formatMessage({ id: "timeline.month.3" }),
        content: intl.formatMessage({ id: "about.timeline.0" }),
      },{
        title: intl.formatMessage({ id: "timeline.month.2" }),
        content: `${intl.formatMessage({ id: "about_jp_timeline_2022_2" })}<br />${intl.formatMessage({ id: "about.timeline.1" })} `,
      }
    ],
    2021:[
      {
        title: intl.formatMessage({ id: "timeline.month.11" }),
        content: intl.formatMessage({ id: "about.timeline.2" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.3" }),
      },{ title:"",content:''}
    ],
    2020:[
      {
        title:  intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.4" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.8" }),
        content: intl.formatMessage({ id: "about.timeline.5" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.6" }),
      }
    ],
    2019:[
      {
        title:intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.7" }),
      },
      {
        title:intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.8" }),
      },{ title:"",content:''}
    ],
    2018:[],
    2017:[
      {
        title:intl.formatMessage({ id: "timeline.month.9" }),
        content: intl.formatMessage({ id: "about.timeline.9" }),
      },
      {
        title: intl.formatMessage({ id: "timeline.month.1" }),
        content: intl.formatMessage({ id: "about.timeline.10" }),
      },{ title:"",content:''}
    ]
  }
  const Time_Line = intl.locale=='ja'?Time_Line_JP:Time_Line_Global


  return (
    <div className={style.TimelineContent}>
     
      <div className={style.HistoryBox}>
        <div className={style.HistoryBoxNum}>{checkedYear}</div>
        <div className={style.title}>{intl.formatMessage({ id: "about.timeline.Milestones", defaultMessage: "Milestones" })}</div>
      </div>
      {/* <Spacer h={80} /> */}
      <div className={style.timeLineBox} style={{height:intl.locale=='ja'?'1100px':'750px'}}>
        <div className={style.timeLineTab}>
          {Object.keys(Time_Line).reverse().map(item => (
            <div key={`year${item}`}
              className={style.tabItem}
              onClick={() => {Time_Line[item].length==0?'':setCheckedYear(item) }}
              style={{
                color: item == checkedYear ? '#fff' :(Time_Line[item].length==0?'rgba(0, 0, 0, 0.2)': "#000"),
                backgroundColor: item == checkedYear ? '#000' : "#fff",
                boxShadow: item == checkedYear ? "0px 2px 4px rgba(0, 0, 0, 0.2)" : "",
                cursor:Time_Line[item].length==0?'auto':'pointer'

              }}
            

            >{item}</div>
          ))}
        </div>

        <div className={style.timeline}  >
        {checkedYear == 2024 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2024]}
          />}
        {checkedYear == 2023 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2023]}
          />}
          {checkedYear == 2022 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2022]}
          />}

          {checkedYear == 2021 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2021]}
          />}
          {checkedYear == 2020 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2020]}
          />}
          {checkedYear == 2019 && <OneBlock
            // key={key}
            // styles={props}
            open={open}
            item={Time_Line[2019]}
          />}
          {checkedYear==2017&&<OneBlock
                // key={key}
                // styles={props}
                open={open}
                item={Time_Line[2017]}
              />} 
        </div>

      </div> 
      {/* <Spacer h={180} /> */}
    </div>
  )
}

export default React.memo(Index)

import React, { useState, useEffect } from 'react';
import { Layout, SEO } from '@/components/index'
import { ScrollingNotice } from '@/new_components/index'
import { Native3Dof, FirstScreen, MoreThan, DetailImg, Ar4All, Highlights, Anchor, SwiperScroll, X1, Compare, CompareMobile, Upto146, Snap, Certified, Lenses, Accessories, X1Intro, SoundBose, WhichOne ,FAQ,SwiperMobile} from '@/new_components/One/index'
import {
    firstScreen_mobile, firstScreen_pc, OneLogo, GF_Follow_TrueStability, ExpandedVisualExperience_Cover_Mobile, bose_Cover_Desktop,
    bose_Cover_Mobile,
    ExpandedVisualExperience_Cover_Desktop, Eye_Cover_Desktop,
    Eye_Cover_Mobile, madetofit_Cover_Desktop,
    madetofit_Cover_Mobile, spec_one
} from '@/images/one/index'
import useBigScreen from "@/hooks/bigScreen"
import { N3DoF_Cover_mobile, N3DoF_Cover_pc,Native3DoF_video } from '@/images/one/index'
import { useIntl } from "react-intl"
import LocalNav from "@/components/LocalNav"
import { Specs } from '@/new_components/BeamPro'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import One_Logo from '@/images/SVG/One_Logo.svg'
import XREAL_EYE_logo from '@/images/SVG/XREAL_EYE_logo.svg'

const Index = () => {
    const countryCode = getCountry()

    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const [probannerType, setProbannerType] = useState('overview')
    const eu_country=['fr-en','it-en','de-en','nl-en','cz-en','es-en']
    const Native3Dof_intro = {
        video:Native3DoF_video,
        title: intl.formatMessage({ id: "one_part4_text1" }),
        desc: intl.formatMessage({ id: "one_part4_text2" }),
        tip: intl.formatMessage({ id: "one_part4_text3" }),
        right:intl.formatMessage({ id: "one_native3dof_tip" }),
        maskWhite:true
    }
    const expanded_intro = {
        pcImg: ExpandedVisualExperience_Cover_Desktop,
        mobileImg: ExpandedVisualExperience_Cover_Mobile,
        title: intl.formatMessage({ id: "one_part8_text1" }),
        desc: intl.formatMessage({ id: "one_part8_text2" }),
        tip: intl.formatMessage({ id: "one_part8_text3" }),
        maskWhite:true
    }
    const cinematic_intro = {
        pcImg: bose_Cover_Desktop,
        mobileImg: bose_Cover_Mobile,
        title: intl.formatMessage({ id: "one_part13_text1" }),
        desc: intl.formatMessage({ id: "one_part13_text2" }),
        tip: intl.formatMessage({ id: "one_part13_text3" }),
        maskBlack:true
    }
    const eye_intro = {
        pcImg: Eye_Cover_Desktop,
        mobileImg: Eye_Cover_Mobile,
        title: <XREAL_EYE_logo />,
        title_if_svg:true,
        desc: intl.formatMessage({ id: "one_part15_text2" }),
        tip: intl.formatMessage({ id: "one_part15_text3" }),
        maskBlack:true
    }
    const fit_intro = {
        pcImg: madetofit_Cover_Desktop,
        mobileImg: madetofit_Cover_Mobile,
        title: intl.formatMessage({ id: "one_part17_text1" }),
        desc: intl.formatMessage({ id: "one_part17_text2" }),
        tip: intl.formatMessage({ id: "one_part17_text3" }),
        maskGrey:true

    }
    const localNav_intro = {
        logo: <One_Logo />,
        buy_link:  buy_link[countryCode].one.shop,
        menublack: false
    }
    const specs_list = [
        {
            title: "onepro_spec_content1_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content1_detail_title"
                }
            ]
        },
        {
            title: "onepro_spec_content2_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content2_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content3_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content3_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content4_title",
            desc: [
                {
                    title: "one_spec_content4_detail_title",
                    desc: "one_spec_content4_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content5_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content5_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content6_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content6_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content7_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content7_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content8_title",
            desc: [
                {
                    title: "one_spec_content8_detail_title",
                    desc: "one_spec_content8_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content9_title",
            desc: [
                {
                    title: "one_spec_content9_detail_title",
                    desc: "one_spec_content9_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content10_title",
            desc: [
                {
                    title: "one_spec_content10_detail_title",
                    desc: "one_spec_content10_detail_desc"
                }
            ]
        }, {
            title: "onepro_spec_content11_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content11_detail_title"
                }
            ]
        }, {
            title: "onepro_spec_content12_title",
            desc: [
                {
                    title: "",
                    desc: "one_spec_content12_detail_title"
                }
            ]
        }

    ]


    return <>
        <Layout menuBlack={probannerType=='overview'?true:false} dontfixed={true}>
            <SEO page="One" />
            <LocalNav intro={localNav_intro} setProbannerType={setProbannerType} probannerType={probannerType} />
            {probannerType == 'overview' && <div>

               
                <FirstScreen pc_img={firstScreen_pc} mobile_img={firstScreen_mobile} logo={OneLogo} text1={intl.formatMessage({ id: "one_slogan1" })} text2={intl.formatMessage({ id: "one_slogan2" })} text_color={'linear-gradient(90deg, #FEE582 0%, #FFBA4F 100%)'} />

                {(countryCode!=='jp'&&countryCode!=='kr')&&  <ScrollingNotice text1={intl.formatMessage({ id: "one_notice1" })} text2={countryCode=='uk'?intl.formatMessage({ id: "one_notice_uk" }):(eu_country.includes(countryCode)?intl.formatMessage({ id: "one_notice_eu" }):  intl.formatMessage({ id: "one_notice" }))} btnColor='yellow' learn_link='/one-pro' buy_link={buy_link[countryCode].one.shop} />}
              
                <MoreThan />
                <Ar4All />
                <DetailImg />

                <Highlights />
                <Native3Dof intro={Native3Dof_intro} />
                <Anchor />
                {ifPc? <SwiperScroll />:<SwiperMobile />}
               
                <X1 />


                <X1Intro />
                {ifPc ? <Compare /> : <CompareMobile />}
                <Native3Dof intro={expanded_intro} />
                <Upto146 />

                <Native3Dof intro={cinematic_intro} />
                <SoundBose />
                {countryCode!=='jp'&&countryCode!=='kr'&&<><Native3Dof intro={eye_intro} />
                <Snap /></>}
                
                <Native3Dof intro={fit_intro} />
                {/* <Certified /> */}
                <Lenses />
                {countryCode!=='jp'&&countryCode
                !=='kr'&&<Accessories bgColor={'#EFECE5'} />}
               <WhichOne />
               

            </div>}
            {
                probannerType=='specs'&& <Specs list={specs_list} ifBlack={true} product={<One_Logo />} img={spec_one} />
            }
            {probannerType=='faq'&&<FAQ />}





           



        </Layout>
    </>
}
export default Index
import React from "react";
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"
import {
    mask_black,
    mask_white,
    mask_grey
}from '@/images/one'

const Native3Dof = ({ intro }) => {
    const ifPc = useBigScreen(768);
    return (
        <div className={style.box}>
            <div className={style.mask}>
                {!!intro.maskBlack && <img src={mask_black} /> } 
                {!!intro.maskWhite && <img src={mask_white} /> } 
                {!!intro.maskGrey && <img src={mask_grey} /> } 
            </div>
            <div className={style.title}>
                {!intro.title_if_svg?<span dangerouslySetInnerHTML={{ __html: `${intro.title}` }}></span>:intro.title}
                

            </div>

            <div className={style.desc}>
                {intro.desc}

            </div>
            {!!intro.tip && <div className={style.tip}  dangerouslySetInnerHTML={{ __html: intro.tip }}>
               
            </div>}
            {!!intro.right && <div className={style.else}>
                {intro.right}
            </div>}
            
            <div className={style.video}>
                {!!intro.video ? <video autoPlay playsInline loop muted width="100%" >
                    <source src={intro.video} type="video/mp4" />
                </video> : <div className={style.videoImg} style={{ backgroundImage: `url(${ifPc ? intro.pcImg : intro.mobileImg})` }}>
                </div>}

            </div>
        </div>
    )
}

export default Native3Dof
import React, { useState } from 'react'
import style from './index.module.less'
import {
    Mode_Follow,
    Mode_Sideview,
    Mode_Anchor,
    Mode_UltraWide,
    GINA_Anchor_1,
    GINA_Anchor_2,
    GINA_Follow_1,
    GINA_SideView_1,
    GINA_Ultrawide_1,
} from '@/images/onepro/index'
import { useIntl } from 'react-intl'
import Yellow_add_icon from '@/images/SVG/Yellow_add_icon.svg'
import useBigScreen from "@/hooks/bigScreen"
import White_add_icon from '@/images/SVG/White_add_icon.svg'

const Mode = () => {
    const intl = useIntl()
    const [showId, setShowId] = useState([0, 0])
    const [ifShow, setIfShow] = useState(false)
    const ifPc = useBigScreen(768)

    const content_arr = [
        {
            img: Mode_Anchor,
            name: intl.formatMessage({ id: "one_part5_content1_title" }),
            desc: intl.formatMessage({ id: "onepro_mode1_desc" }),
            type:"Native 3 DoF",
            btn: [
                {
                    text: intl.formatMessage({ id: "onepro_mode1_btn1" }),
                    content: {
                        img: GINA_Anchor_1,
                        title: intl.formatMessage({ id: "onepro_mode1_btn1_desc" })
                    }
                }, {
                    text: intl.formatMessage({ id: "onepro_mode1_btn2" }),
                    content: {
                        img: GINA_Anchor_2,
                        title: intl.formatMessage({ id: "onepro_mode1_btn2_desc" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_Follow,
            name: intl.formatMessage({ id: "one_part5_content2_title" }),
            desc: intl.formatMessage({ id: "onepro_mode2_desc" }),
            type:"0 DoF",

            btn: [
                {
                    text: intl.formatMessage({ id: "onepro_mode2_btn1" }),
                    content: {
                        img: GINA_Follow_1,
                        title: intl.formatMessage({ id: "onepro_mode2_btn1_desc" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_UltraWide,
            name: intl.formatMessage({ id: "one_part5_content3_title" }),
            desc: intl.formatMessage({ id: "onepro_mode3_desc" }),
            type:"Native 3 DoF",
            btn: [
                {
                    text: intl.formatMessage({ id: "onepro_mode3_btn1" }),
                    content: {
                        img: GINA_Ultrawide_1,
                        title: intl.formatMessage({ id: "onepro_mode3_btn1_desc" }),
                        desc: ""
                    }
                }
            ]

        }, {
            img: Mode_Sideview,
            name: intl.formatMessage({ id: "one_part5_content4_title" }),
            desc: intl.formatMessage({ id: "onepro_mode4_desc" }),
            type:"0 DoF",

            btn: [
                {
                    text: intl.formatMessage({ id: "onepro_mode4_btn1" }),
                    content: {
                        img: GINA_SideView_1,
                        title: intl.formatMessage({ id: "onepro_mode4_btn1_desc" }),
                        desc: ""
                    }
                }
            ]

        },
    ]

    return (
        <div className={style.box}>
            <div className={style.content}>
                {content_arr.map((item, index) => (
                    <div className={style.contentItem} key={`onepro_mode_${index}`}>
                        <div className={style.contentItemVideo}>
                            <video autoPlay playsInline loop muted width="100%" >
                                <source src={item.img} type="video/mp4" />
                            </video>
                            <div className={style.type}>{item.type}</div>
                        </div>
                        <div className={style.contentItemBox}>
                            <div className={style.contentItemBoxTitle}>
                                {item.name}
                            </div>
                            <div className={style.contentItemBoxDesc} dangerouslySetInnerHTML={{ __html: `${item.desc}` }}>
                               
                            </div>
                            {item.btn.map((value, key) => (
                                <div className={style.contentItemBoxBtn} key={`onepro_mode${index}_${key}`}
                                onClick={() => {
                                    setShowId([index, key]);
                                    setIfShow(true)
                                }}
                                >
                                    {value.text}
                                    <Yellow_add_icon />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {ifPc ? <div className={style.show} style={{ opacity: ifShow ? 1 : 0, zIndex: ifShow ? 2 : '-1' }}>
                <div className={style.showBox} style={{backgroundImage:`url(${ content_arr[showId[0]].btn[showId[1]].content.img})`}}>
                    <div className={style.showBoxClose} onClick={() => setIfShow(false)}>
                        <White_add_icon />
                    </div>
                    <div className={style.showBoxTitle}>
                        {
                            content_arr[showId[0]].btn[showId[1]].text
                           
                        }
                    </div>
                    <div className={style.showBoxDesc}>
                        {
                           content_arr[showId[0]].btn[showId[1]].content.title
                        }
                    </div>
                </div>
            </div> : <div className={style.show} style={{ opacity: ifShow ? 1 : 0, zIndex: ifShow ? 2 : '-1' }}>
                <div className={style.showBoxClose} onClick={() => setIfShow(false)}>
                    <White_add_icon />
                </div>
                <div className={style.showBox} style={{backgroundImage:`url(${ content_arr[showId[0]].btn[showId[1]].content.img})`}}>


                </div>
                <div className={style.showBoxTitle}>
                    {
                         content_arr[showId[0]].btn[showId[1]].text
                    }
                </div>
                <div className={style.showBoxDesc}>
                    {
                        content_arr[showId[0]].btn[showId[1]].content.title
                    }
                </div>
            </div>}
        </div>
    )
}

export default Mode
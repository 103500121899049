import React from 'react'
import style from './index.module.less'
import {
    compare_1,
    compare_2
} from '@/images/onepro'
import { useIntl } from "react-intl"

const Compared = () =>{
    const intl=useIntl()

    const compared_arr=[
        {
            img:compare_1,
            title: intl.formatMessage({ id: "onepro_compared1_title" }),
            desc: intl.formatMessage({ id: "onepro_compared1_desc" }),
            type:['shake','shake-free']
        }, {
            img:compare_2,
            title: intl.formatMessage({ id: "onepro_compared2_title" }),
            desc: intl.formatMessage({ id: "onepro_compared2_desc" }),
            type:['Distortion','Distortion-Free']
        }
    ]
    
    return(
        <div className={style.box}>
            <div className={style.compare}>
                {compared_arr.map((item,index)=>(
                    <div className={style.compareItem} key={`onepro_compared_${index}`}>
                        <div className={style.compareItemImg} style={{backgroundImage:`url(${item.img})`}}>
                            {item.type.map((typeItem,typeIndex)=>(
                                <div className={style.compareItemImgType} key={`onpro_compare_type${index}${typeIndex}`}>{typeItem}</div>
                            ))}
                        </div>
                        <div className={style.compareItemTitle}>
                            {item.title}
                        </div>
                        <div className={style.compareItemDesc}>
                            {item.desc}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Compared
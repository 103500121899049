import React, { useEffect } from "react";
import style from './index.module.less'
import { XIChip } from "@/images/onepro/index";
import { useIntl } from "react-intl";
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBigScreen from "@/hooks/bigScreen"
import {
    ar4all_mask1,
    ar4all_mask2
} from '@/images/one/index'

const X1Chip = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()
    const ifPc = useBigScreen(989)
    useEffect(() => {
        if (ifPc) {
            const box2 = document.querySelector('.onepro_x1chip2_box2');
            const currentHeight = box2.offsetHeight / 2;
            const additionalHeight = window.innerHeight * 0.5;
            // const divHeight ='-'+ currentHeight + additionalHeight + 'px';
            const divHeight = currentHeight + additionalHeight;
            const divHeightPX = '-' + divHeight + 'px'

            if (document.querySelector('.x-gina-x1') && window.innerWidth > 989) {
                gsap.registerPlugin(ScrollTrigger)
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: '.x-gina-x1',
                        start: "top top",
                        end: "bottom top",
                        scrub: true,
                        pin: true,
                        // markers: true,
                    }
                })
                tl.to(".x-gina-x1__content", {
                    y: divHeightPX,
                    duration: 1,
                    ease: "power2.out"
                })
            }
        } 
    }, [])
    return (
        <div className={classNames(style.box, 'x-gina-x1')}>
            <img src={ar4all_mask1} className={style.mask1} />
            <img src={ar4all_mask2} className={style.mask2}/>
            {/* x-gina-x1 */}
            <img
                src={XIChip}
                className={style.chip}
                loading="lazy"
                width="auto"
                height="auto"
            />
            {/* x-gina-x1__img" */}
            <div className={classNames(style.content, 'x-gina-x1__content')}>
                {!!ifPc && <div className={classNames(style.contentBox, style.content1)}>
                    <h2>{intl.formatMessage({ id: "onepro_x1chip1_title" })}</h2>
                </div>}

                <div className={classNames(style.content2, 'onepro_x1chip2_box2')} >
                    <h2>{intl.formatMessage({ id: "onepro_x1chip2_title" })}</h2>
                    <h4>{intl.formatMessage({ id: "onepro_x1chip2_desc" })}</h4>
                    <p dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "onepro_x1chip2_tip" })}` }}></p>
                </div>
            </div>
        </div>
    )
}
export default X1Chip